<template>
  <div>
    <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="600px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="title_wrapper">
            <div class="title_left">
              <span class="text-h5"
                >{{ $t("My Holidays") }}{{ formTitle }}</span
              >
            </div>
            <div class="title_right">
              <v-btn
                :color="current_status_class"
                dark
                large
                class="btn_status"
              >
                {{ statusTypes[selected.status] }}
              </v-btn>
            </div>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12" class="d-flex justify-center">
                  <v-radio-group
                    v-model="selected.holidayType"
                    row
                    required
                    :rules="requiredRules"
                  >
                    <template v-for="holidayTypeItem in holidayTypesSelect">
                      <v-radio
                        :key="holidayTypeItem.value"
                        :label="holidayTypeItem.label"
                        :value="holidayTypeItem.value"
                      ></v-radio>
                    </template>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row v-if="selected.holidayType !== 'VACATION'">
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    v-model="selected.paidType"
                    v-bind:label="$t('Type')"
                    :items="paidTypesSelect"
                    item-text="label"
                    item-value="value"
                    filled
                    required
                    :rules="requiredRules"
                    @change="onChangePaidType"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-if="selected.holidayType !== 'VACATION'">
                <v-col cols="12" sm="12" md="12">
                  <span :class="`text-h5`"
                    >{{ this.$t("availabledays") }} :
                    {{ this.selected.limit_paid_days }}
                    {{
                      this.isNaturals_paid_day_limits[this.selected.paidType] &&
                      this.isNaturals_paid_day_limits[
                        this.selected.paidType
                      ] === true
                        ? "Naturales"
                        : ""
                    }}
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-menu
                    ref="date_menu_start"
                    v-model="date_menu_start"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="selected.startDate"
                        v-bind:label="$t('start_date')"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        required
                        :rules="
                          selected.isSelectableDates
                            ? requiredRules
                            : selectableRules
                        "
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="selected.startDate"
                      no-title
                      @input="date_menu_start = false"
                      :first-day-of-week="1"
                      :locale="this.$i18n.locale"
                      :reactive="reactive_start"
                      :readonly="selected.isChecking || !selected.isEditable"
                      @change="onChangeDates"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-menu
                    ref="date_menu_end"
                    v-model="date_menu_end"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="selected.endDate"
                        v-bind:label="$t('end_date')"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        required
                        :rules="
                          selected.isSelectableDates
                            ? requiredRules
                            : selectableRules
                        "
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="selected.endDate"
                      no-title
                      @input="date_menu_end = false"
                      :first-day-of-week="1"
                      :locale="this.$i18n.locale"
                      :reactive="reactive_end"
                      :readonly="selected.isChecking || !selected.isEditable"
                      :min="selected.startDate || ''"
                      @change="onChangeDates"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <span class="text-h5">{{ this.$t("Holidays") }}</span>
                  <v-icon
                    v-if="
                      !selected.isChecking && selected.status !== 'APPROVED'
                    "
                    large
                    color="primary"
                    class="ml-2"
                    style="cursor: pointer;"
                    @click="onClickAddHolidays"
                  >
                    mdi-plus-circle
                  </v-icon>
                </v-col>
              </v-row>
              <v-row>
                <template
                  v-for="(holidayitem, index) in selected.holidaypublics"
                >
                  <v-col cols="12" sm="10" md="5" :key="index">
                    <v-menu
                      v-model="date_menu_publicholidays[index]"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="holidayitem['publicholiday']"
                          v-bind:label="$t('Holiday Date')"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          required
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="holidayitem['publicholiday']"
                        no-title
                        @input="date_menu_publicholidays[index] = false"
                        :first-day-of-week="1"
                        :locale="holidayitem['locale']"
                        :reactive="reactive_public_holidays[index]"
                        :readonly="selected.isChecking || !selected.isEditable"
                        :min="selected.startDate || ''"
                        :max="selected.endDate || ''"
                        :allowed-dates="allowedDates"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                    md="1"
                    :key="index + selected.holidaypublics.length"
                    style="display: flex; align-items: center; justify-content: center;"
                  >
                    <v-icon
                      v-if="
                        !selected.isChecking && selected.status !== 'APPROVED'
                      "
                      medium
                      color="red"
                      class="ml-2"
                      style="cursor: pointer;"
                      @click="onClickRemoveHolidays(index)"
                    >
                      mdi-delete
                    </v-icon>
                  </v-col>
                </template>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <span
                    :class="
                      `text-h5 ${
                        selected.isOverflowCurrentHolidayPending ||
                        (this.selected.holidayType !== 'VACATION' &&
                          this.selected.isOverFlowPaidLimitDays)
                          ? `color-red`
                          : ``
                      }`
                    "
                    >{{ this.$t("Total Days Requested") }} :
                    {{ this.selected.request_day }}</span
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="selected.publicholiday_municipality"
                    v-bind:label="$t('publicholiday_municipality')"
                    :readonly="selected.isChecking || !selected.isEditable"
                    :required="selected.holidaypublics.length > 0"
                    :rules="
                      selected.holidaypublics.length > 0 ? requiredRules : []
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="selected.employee_comments"
                    v-bind:label="$t('comments') + ' ' + $t('User')"
                    :readonly="selected.isChecking || !selected.isEditable"
                    ref="textarea_employee_comments"
                    v-bind:class="
                      'employee_comments_text ' +
                        (selected.isChecking ? 'readonly' : '')
                    "
                    required
                    :rules="requiredRules"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row v-if="selected.isChecking && selected.isOnlyAdminEditable">
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    v-model="selected.status"
                    v-bind:label="$t('salesforce.status')"
                    :items="statusTypesSelect"
                    item-text="label"
                    item-val="value"
                    filled
                    required
                    :rules="requiredRules"
                    @change="onChangeApprovalStatus(selected.status)"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-if="selected.id !== undefined">
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="selected.responsible_comments"
                    v-bind:label="
                      $t('comments') + ' ' + $t('expensesParentUserName')
                    "
                    :readonly="!selected.isChecking"
                    ref="textarea_responsible_comments"
                    v-bind:class="
                      'responsible_comments_text ' +
                        (!selected.isChecking ? 'readonly' : '')
                    "
                    :required="
                      selected.isChecking && selected.status === 'INCIDENCE'
                    "
                    :rules="
                      selected.isChecking && selected.status === 'INCIDENCE'
                        ? requiredRules
                        : []
                    "
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="
                selected.id !== undefined &&
                  !selected.isChecking &&
                  selected.initialStatus !== 'APPROVED'
              "
              color="blue darken-1"
              text
              :loading="isDeleting"
              @click="onClickDelete(selected)"
              ref="btnDelete"
            >
              {{ $t("delete") }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="closeEditDialog">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              v-if="
                (selected.initialStatus === 'APPROVED' &&
                  (this.isAdmin || this.isSubAdmin)) ||
                  selected.initialStatus !== 'APPROVED'
              "
              color="blue darken-1"
              text
              :loading="isSaving"
              @click="onSaveClick(selected)"
              ref="btnSave"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="3">
            <h4>
              {{
                isteam === "my"
                  ? this.$t("My Holidays")
                  : this.$t("My Team Holidays")
              }}
            </h4>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <Statistics
              :isPageCat="`holiday`"
              :data="statistics_data"
              :isteam="isteam"
            />
          </v-col>
          <v-col cols="12" sm="12" md="3">
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
                class="text-right"
                style="display: flex; align-items: flex-end; justify-content: flex-end;"
              >
                <h5 v-if="isteam === 'my'">
                  username: {{ isAuthenticated && user.username }}
                </h5>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="text-right">
                <v-btn
                  v-if="isteam === 'my'"
                  color="primary"
                  dark
                  @click="onItemEdit()"
                >
                  {{ $t("newitem") }}
                </v-btn>
                <v-btn
                  color="warning"
                  dark
                  class="ml-2"
                  @click="downloadExcel()"
                >
                  <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
                  Export
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12" sm="12" md="12">
            <ve-table
              id="holiday-table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="isteam === 'my' ? my_columns : team_columns"
              :table-data="tableData"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaltableData"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import download from "js-file-download";
import moment from "moment";
import _ from "lodash";
import Statistics from "../statistics";

export default {
  name: "Holiday",
  props: ["isteam"],
  components: {
    Statistics
  },
  data() {
    return {
      statistics_data: {
        daysapproved: 0,
        current_holidaypending: 0,
        last_holidaypending: 0,
        dayspendingapproval: 0
      },
      statusTypes: [],
      statusTypesSelect: [],
      holidayTypes: [],
      holidayTypesSelect: [],
      paidTypes: [],
      paidTypesSelect: [],
      paid_day_limits: null,
      isNaturals_paid_day_limits: {},
      staticPublicHolidays: [],
      holidaystaticdays: [],
      user_roles: [],
      user_status: [],
      tableData: [],
      totaltableData: 0,
      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterCriteria: {
        date: {},
        username: {},
        usertype: {},
        user_status: {},
        parentId: {},
        daysrequested: {},
        startDate: {},
        endDate: {},
        status: {},
        numberofpublicholidays: {},
        publicholiday_municipality: {},
        employee_comments: {},
        responsible_comments: {},
        approvername: {},
        holidayType: {},
        paidType: {}
      },
      filterable_col_list: [
        "date",
        "username",
        "usertype",
        "user_status",
        "parentId",
        "daysrequested",
        "startDate",
        "endDate",
        "numberofpublicholidays",
        "approvername",
        "employee_comments",
        "responsible_comments",
        "status",
        "publicholiday_municipality",
        "holidayType",
        "paidType"
      ],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        },
        bodyCellClass: ({ row, column, rowIndex }) => {
          if (row.status === "APPROVED") {
            return "table-body-cell-approved";
          } else if (row.status === "INCIDENCE") {
            return "table-body-cell-incidence";
          }
        }
      },
      showEmpty: false,
      filterable_parent_list: [],

      /********** form-validation options **********/
      valid: true,
      requiredRules: [v => !!v || "Required"],
      selectableRules: [
        v => !!v || "Required",
        v =>
          "Las fechas seleccionadas contienen una fecha donde ya has solicitado vacaciones."
      ],

      /********** create or edit dialog options **********/
      showEdit: false,
      selected: null,
      editedIndex: -1,
      current_status_class: "error",
      isSaving: false,
      isDeleting: false,
      date_menu_start: false,
      date_menu_end: false,
      reactive_start: false,
      reactive_end: false,
      date_menu_publicholidays: [],
      reactive_public_holidays: [],
      allowedDates: val => {
        let day = moment(val).day();
        return day >= 1 && day <= 5;
      }
    };
  },
  watch: {
    $route(to, from) {
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        this.filterCriteria[key] = Object.assign({});
      }
      this.getDataFromApi();
    }
  },
  computed: {
    ...mapGetters("auth", [
      "isAuthenticated",
      "isGPV",
      "isAdmin",
      "isSubAdmin",
      "user"
    ]),
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? ""
        : " - " + this.$t("edititem");
    },
    col_date() {
      return {
        key: "date",
        title: this.$t("Date"),
        field: "date",
        align: "left",
        width: 150,
        sortBy: "",
        fixed: "left",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["date"]["from"]}
                  value={this.filterCriteria["date"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["date"]["to"]}
                  value={this.filterCriteria["date"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "date")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_username() {
      return {
        key: "username",
        title: this.$t("salesforce.UserName"),
        field: "username",
        align: "left",
        width: 200,
        sortBy: "",
        fixed: "left",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <span>{row.user && row.user.name + " " + row.user.surname}</span>
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["username"]}
                  apiUrl={`holiday/getfilterlist/${this.isteam}`}
                  columnName={`username`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["username"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "username")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_user_status() {
      return {
        key: "user_status",
        title: `${this.$t("salesforce.status")} usuario`,
        field: "user_status",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <span>{row.user && this.gennera_user_status[row.user.status]}</span>
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxes
                  v-model={this.filterCriteria["user_status"]}
                  dataList={this.user_status}
                  filterCriteria={this.filterCriteria["user_status"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "user_status")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_usertype() {
      return {
        key: "usertype",
        title: this.$t("salesforce.user_type"),
        field: "usertype",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.user && row.user.role}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxes
                  v-model={this.filterCriteria["usertype"]}
                  dataList={this.user_roles}
                  filterCriteria={this.filterCriteria["usertype"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "usertype")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_parentId() {
      return {
        key: "parentId",
        title: this.$t("expensesParentUserName"),
        field: "parentId",
        align: "left",
        width: 180,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <span>
              {row.user &&
                row.user.Parent &&
                row.user.Parent.name + " " + row.user.Parent.surname}
            </span>
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["parentId"]}
                  apiUrl={`holiday/getfilterlist/${this.isteam}`}
                  columnName={`parentId`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["parentId"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "parentId")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_holidayType() {
      return {
        key: "holidayType",
        title: this.$t("Type"),
        field: "holidayType",
        align: "left",
        width: 180,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <div>{row.holidayTypeLabel}</div>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            let holidayTypeVales = Object.values(this.holidayTypes);
            return (
              <div class="custom-filter">
                <filterableCheckboxes
                  v-model={this.filterCriteria["holidayType"]}
                  dataList={holidayTypeVales}
                  filterCriteria={this.filterCriteria["holidayType"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "holidayType")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_paidType() {
      return {
        key: "paidType",
        title: this.$t("paidleave") + " " + this.$t("Type"),
        field: "paidType",
        align: "left",
        width: 250,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <div>{row.paidTypeLabel}</div>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            let paidTypeVales = Object.values(this.paidTypes);
            return (
              <div class="custom-filter">
                <filterableCheckboxes
                  v-model={this.filterCriteria["paidType"]}
                  dataList={paidTypeVales}
                  filterCriteria={this.filterCriteria["paidType"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "paidType")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_daysrequested() {
      return {
        key: "daysrequested",
        title: this.$t("Days Requested"),
        field: "daysrequested",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              {row.daysStatus && row.daysStatus.request_day && (
                <span>{row.daysStatus.request_day}</span>
              )}
            </div>
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["daysrequested"]["from"]}
                  value={this.filterCriteria["daysrequested"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["daysrequested"]["to"]}
                  value={this.filterCriteria["daysrequested"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "daysrequested")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_startDate() {
      return {
        key: "startDate",
        title: this.$t("start_date"),
        field: "startDate",
        align: "left",
        width: 150,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["startDate"]["from"]}
                  value={this.filterCriteria["startDate"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["startDate"]["to"]}
                  value={this.filterCriteria["startDate"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "startDate")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_endDate() {
      return {
        key: "endDate",
        title: this.$t("end_date"),
        field: "endDate",
        align: "left",
        width: 150,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["endDate"]["from"]}
                  value={this.filterCriteria["endDate"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["endDate"]["to"]}
                  value={this.filterCriteria["endDate"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "endDate")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_numberofpublicholidays() {
      return {
        key: "numberofpublicholidays",
        title: this.$t("Number of Public Hoidays"),
        field: "numberofpublicholidays",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              {row.holidaypublics && row.holidaypublics.length > 0 && (
                <span>{row.holidaypublics.length}</span>
              )}
            </div>
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={
                    this.filterCriteria["numberofpublicholidays"]["from"]
                  }
                  value={this.filterCriteria["numberofpublicholidays"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["numberofpublicholidays"]["to"]}
                  value={this.filterCriteria["numberofpublicholidays"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "numberofpublicholidays")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_publicholidays() {
      return {
        key: "publicholidays",
        title: this.$t("Public Holidays"),
        field: "publicholidays",
        align: "center",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              {row.holidaypublics &&
                row.holidaypublics.map(item => {
                  return (
                    <div>
                      <span>{item.publicholiday}</span>
                      <br />
                    </div>
                  );
                })}
            </div>
          );
        }
      };
    },
    col_publicholiday_municipality() {
      return {
        key: "publicholiday_municipality",
        title: this.$t("publicholiday_municipality"),
        field: "publicholiday_municipality",
        align: "left",
        width: 250,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["publicholiday_municipality"]}
                  apiUrl={`holiday/getfilterlist/${this.isteam}`}
                  columnName={`publicholiday_municipality`}
                  isFullText={false}
                  filterCriteria={
                    this.filterCriteria["publicholiday_municipality"]
                  }
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "publicholiday_municipality")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_employee_comments() {
      return {
        key: "employee_comments",
        title: this.$t("comments") + " " + this.$t("User"),
        field: "employee_comments",
        align: "left",
        width: 250,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["employee_comments"]}
                  apiUrl={`holiday/getfilterlist/${this.isteam}`}
                  columnName={`employee_comments`}
                  isFullText={false}
                  filterCriteria={this.filterCriteria["employee_comments"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "employee_comments")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_responsible_comments() {
      return {
        key: "responsible_comments",
        title: this.$t("comments") + " " + this.$t("expensesParentUserName"),
        field: "responsible_comments",
        align: "left",
        width: 250,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["responsible_comments"]}
                  apiUrl={`holiday/getfilterlist/${this.isteam}`}
                  columnName={`responsible_comments`}
                  isFullText={false}
                  filterCriteria={this.filterCriteria["responsible_comments"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "responsible_comments")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_approvername() {
      return {
        key: "approvername",
        title: this.$t("Approved By"),
        field: "approvername",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <span>
              {row.hdapprover &&
                row.hdapprover.name + " " + row.hdapprover.surname}
            </span>
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["approvername"]}
                  apiUrl={`holiday/getfilterlist/${this.isteam}`}
                  columnName={`approvername`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["approvername"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "approvername")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_status() {
      return {
        key: "status",
        title: this.$t("salesforce.status"),
        field: "status",
        align: "left",
        width: 180,
        sortBy: "",
        fixed: "right",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <div>{row.status_label}</div>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            let statusVales = Object.values(this.statusTypes);
            return (
              <div class="custom-filter">
                <filterableCheckboxes
                  v-model={this.filterCriteria["status"]}
                  dataList={statusVales}
                  filterCriteria={this.filterCriteria["status"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "status")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_edit() {
      return {
        key: "edit",
        title: this.$t("salesforce.Edit"),
        field: "actions",
        align: "center",
        width: 100,
        fixed: "right",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              <v-icon
                medium
                color="primary"
                class="mr-2"
                on-click={() => this.onItemEdit(row)}
              >
                mdi-pencil
              </v-icon>
            </div>
          );
        }
      };
    },
    my_columns() {
      return [
        this.col_date,
        this.col_holidayType,
        this.col_paidType,
        this.col_daysrequested,
        this.col_startDate,
        this.col_endDate,
        this.col_publicholiday_municipality,
        this.col_publicholidays,
        this.col_employee_comments,
        this.col_responsible_comments,
        this.col_approvername,
        this.col_status,
        this.col_edit
      ];
    },
    team_columns() {
      return [
        this.col_date,
        this.col_username,
        this.col_user_status,
        this.col_usertype,
        this.col_parentId,
        this.col_holidayType,
        this.col_paidType,
        this.col_daysrequested,
        this.col_startDate,
        this.col_endDate,
        this.col_publicholiday_municipality,
        this.col_numberofpublicholidays,
        this.col_publicholidays,
        this.col_employee_comments,
        this.col_responsible_comments,
        this.col_approvername,
        this.col_status,
        this.col_edit
      ];
    }
  },
  methods: {
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#holiday-table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (key === "date") {
            let convert_value = {
              from: "",
              to: ""
            };
            if (
              value["from"] !== "" &&
              value["from"] !== null &&
              value["from"] !== undefined
            ) {
              convert_value["from"] = value["from"];
              filterModel.push({
                columnField: key,
                filterValue: convert_value
              });
            }
            if (
              value["to"] !== "" &&
              value["to"] !== null &&
              value["to"] !== undefined
            ) {
              convert_value["to"] = value["to"];
              filterModel.push({
                columnField: key,
                filterValue: convert_value
              });
            }
          } else if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value
            });
          }
        }
      }
      let filter_columns = document.querySelectorAll(
        "#holiday-table .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      if (this.isteam !== undefined) {
        query_params_string += "&isteam=" + this.isteam;
      }
      return query_params_string;
    },
    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "holiday";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const resp = await ApiService.get(fetch_url);
        this.tableData = resp.data;
        if (this.tableData.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totaltableData = resp.total;
        this.statusTypes = resp.statusTypes;
        this.statusTypesSelect = resp.statusTypesSelect;
        this.holidayTypes = resp.holidayTypes;
        this.holidayTypesSelect = resp.holidayTypesSelect;
        this.paidTypes = resp.paidTypes;
        this.paidTypesSelect = resp.paidTypesSelect;
        this.paid_day_limits = resp.paid_day_limits;
        this.isNaturals_paid_day_limits = resp.isNaturals_paid_day_limits;
        this.filterable_parent_list = resp.parent_list;
        this.statistics_data = resp.statistics_data;
        this.staticPublicHolidays = resp.staticPublicHolidays;
        this.user_roles = resp.user_roles;
        this.user_status = resp.user_status;
        this.close();
      } catch (error) {
        this.close();
      }
    },

    onChangeApprovalStatus(val) {
      switch (val) {
        case "APPROVED":
          this.current_status_class = "success";
          break;
        case "INCIDENCE":
          this.current_status_class = "warning";
          break;
        case "PENDINGAPPROVAL":
          this.current_status_class = "primary";
          break;
        default:
          this.current_status_class = "primary";
          break;
      }
    },
    onClickAddHolidays() {
      this.date_menu_publicholidays.push(false);
      this.reactive_public_holidays.push(false);
      let push_item = {
        id: undefined,
        publicholiday:
          this.selected.startDate || new Date().toISOString().slice(0, 10),
        holidayId: this.selected.id,
        locale: this.$i18n.locale
      };
      this.selected.holidaypublics.push(push_item);
      this.onChangeDates();
    },
    onClickRemoveHolidays(index) {
      let search_item = this.selected.holidaypublics[index].publicholiday;
      if (
        !this.staticPublicHolidays.includes(search_item.slice(5)) &&
        !this.holidaystaticdays.includes(search_item)
      ) {
        this.selected.holidaypublics = this.selected.holidaypublics.filter(
          item => item.publicholiday !== search_item
        );
        this.onChangeDates();
      }
    },
    addStaticHolidays(startDate, endDate) {
      let startMoment = moment(startDate);
      let endMoment = moment(endDate);
      let period_year_list = [];
      let i = 0;
      if (startMoment.year() === endMoment.year()) {
        period_year_list.push(startMoment.year());
      } else {
        for (i = startMoment.year(); i <= endMoment.year(); i++) {
          period_year_list.push(i);
        }
      }
      let searchable_public_holidays = [];
      for (const year of period_year_list) {
        for (const staticholiday of this.staticPublicHolidays) {
          let holiday_item = year + "-" + staticholiday;
          searchable_public_holidays.push(holiday_item);
        }
      }
      if (this.holidaystaticdays.length > 0) {
        this.holidaystaticdays.map(dateItem => {
          searchable_public_holidays.push(dateItem);
        });
      }
      if (searchable_public_holidays.length > 0) {
        searchable_public_holidays.sort();
      }
      for (const holiday_item of searchable_public_holidays) {
        if (holiday_item >= startDate && holiday_item <= endDate) {
          let filtered = this.selected.holidaypublics.filter(
            item => item.publicholiday === holiday_item
          );
          if (filtered.length === 0) {
            let push_item = {
              id: undefined,
              publicholiday: holiday_item,
              holidayId: this.selected.id,
              locale: this.$i18n.locale
            };
            this.selected.holidaypublics.push(push_item);
          }
        } else {
          let remove_index = this.selected.holidaypublics.findIndex(
            x => x.publicholiday === holiday_item
          );
          if (remove_index > -1) {
            this.selected.holidaypublics.splice(remove_index, 1);
          }
        }
      }
      this.selected.holidaypublics.sort(function(a, b) {
        var keyA = a.publicholiday,
          keyB = b.publicholiday;
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
    },
    initializeHolidaystaticdays(staticdays) {
      if (this.holidaystaticdays.length > 0) {
        for (const staticday of this.holidaystaticdays) {
          let remove_index = this.selected.holidaypublics.findIndex(
            x => x.publicholiday === staticday
          );
          if (remove_index > -1) {
            this.selected.holidaypublics.splice(remove_index, 1);
          }
        }
      }
      this.holidaystaticdays = staticdays;
    },
    async onChangeDates() {
      if (this.selected.startDate > this.selected.endDate) {
        this.selected.endDate = this.selected.startDate;
      }
      if (this.selected.startDate && this.selected.endDate) {
        try {
          let fetch_url = `holiday/checkselectabledates/${this.selected.startDate}/${this.selected.endDate}/${this.selected.id}`;
          let response = await ApiService.get(fetch_url);
          this.selected.isSelectableDates = response.success;
          this.initializeHolidaystaticdays(response.holidaystaticdays);
        } catch (error) {
          console.log(error);
        }
      }
      if (this.selected.startDate && this.selected.endDate) {
        this.addStaticHolidays(this.selected.startDate, this.selected.endDate);
      } else {
        return;
      }
      let days_requested = await this.get_holidays_requested(
        this.selected,
        this.user,
        this.isNaturals_paid_day_limits
      );
      this.selected.request_day = days_requested.request_day;

      if (this.selected.holidayType !== "VACATION") {
        if (this.selected.request_day > this.selected.limit_paid_days) {
          this.selected.isOverFlowPaidLimitDays = true;
          logError(`Has cogido más días de los permitidos.`);
        } else {
          this.selected.isOverFlowPaidLimitDays = false;
        }
        this.selected.isOverflowCurrentHolidayPending = false;
      } else {
        if (
          this.selected.request_day >
          this.statistics_data.current_holidaypending + 10
        ) {
          this.selected.isOverflowCurrentHolidayPending = true;
          logError(
            `Ha solicitado 10 días de vacaciones más de los que tiene pendientes.`
          );
        } else {
          this.selected.isOverflowCurrentHolidayPending = false;
        }
        this.selected.isOverFlowPaidLimitDays = false;
      }
    },
    onItemEdit(item) {
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },
    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    createSelectedModel(item) {
      let model = {
        id: _.get(item, "id"),
        userId: _.get(item, "userId"),
        date: _.get(item, "date"),
        startDate: _.get(item, "startDate"),
        endDate: _.get(item, "endDate"),
        publicholiday_municipality: _.get(item, "publicholiday_municipality"),
        employee_comments: _.get(item, "employee_comments"),
        responsible_comments: _.get(item, "responsible_comments"),
        approver: _.get(item, "approver"),
        status: _.get(item, "status"),
        initialStatus: _.get(item, "status"),
        holidaypublics: _.get(item, "holidaypublics"),
        user: _.get(item, "user"),
        daysrequested: _.get(item, "daysrequested"),
        holidayType: _.get(item, "holidayType"),
        paidType: _.get(item, "paidType"),
        limit_paid_days: _.get(item, "limit_paid_days"),
        isChecking: false,
        isEditable: true,
        isOnlyAdminEditable: true,
        isSelectableDates: true,
        isOverflowCurrentHolidayPending: false,
        isOverFlowPaidLimitDays: false,
        isSelectedNaturalMode: false
      };
      if (
        model.initialStatus === "APPROVED" &&
        !this.isAdmin &&
        !this.isSubAdmin
      ) {
        model.isOnlyAdminEditable = false;
      }
      if (model.holidaypublics === undefined) {
        this.date_menu_publicholidays = [];
        this.reactive_public_holidays = [];
        model.holidaypublics = [];
      } else {
        model.holidaypublics = model.holidaypublics.map(item => {
          this.date_menu_publicholidays.push(false);
          this.reactive_public_holidays.push(false);
          return { ...item, locale: this.$i18n.locale };
        });
      }
      if (model.id === undefined) {
        model.isChecking = false;
        model.date = new Date().toISOString().slice(0, 10);
      } else if (model.userId !== this.user.id) {
        model.isChecking = true;
      }
      if (!model.isChecking && model.status === "APPROVED") {
        model.isEditable = false;
      }
      if (model.id === undefined) {
        model.status = "PENDINGAPPROVAL";
      }
      this.onChangeApprovalStatus(model.status);
      // let days_requested = await this.get_holidays_requested(
      //   model.startDate,
      //   model.endDate,
      //   model.holidaypublics,
      //   model.userId
      // );
      model.request_day = model.daysrequested ? model.daysrequested : 0;
      if (!model.id && !model.holidayType) {
        model.holidayType = "VACATION";
      }
      console.log(model);
      return model;
    },
    async onSaveClick(item) {
      if (
        this.selected.isChecking &&
        this.selected.status === "INCIDENCE" &&
        !this.selected.responsible_comments
      ) {
        logError("Los comentarios del responsable son necesarios.");
        this.$refs.textarea_responsible_comments.focus();
        return;
      }
      if (!this.selected.isChecking) {
        if (
          this.selected.holidayType !== "VACATION" &&
          this.selected.isOverFlowPaidLimitDays
        ) {
          logError(`Has cogido más días de los permitidos.`);
          return;
        } else {
          if (this.selected.isOverflowCurrentHolidayPending) {
            logError(
              `Ha solicitado 10 días de vacaciones más de los que tiene pendientes.`
            );
            return;
          }
        }
      }
      if (this.$refs.form.validate()) {
        let is_valid = true;
        if (is_valid) {
          try {
            this.isSaving = true;
            let body = item;
            if (!this.selected.isChecking) {
              body.status = "PENDINGAPPROVAL";
            }
            if (
              this.selected.isChecking &&
              !this.isAdmin &&
              !this.isSubAdmin &&
              body.status === "APPROVED"
            ) {
              if (
                window.confirm(
                  "Si apruebas la vacaciones no podrá modificarse más."
                )
              ) {
                await this.saveData(body);
              }
            } else {
              await this.saveData(body);
            }
            this.isSaving = false;
          } catch (error) {
            this.isSaving = false;
          }
        }
      }
    },
    async saveData(body) {
      if (body.id) {
        await ApiService.put(`holiday/${body.id}`, body);
      } else {
        await ApiService.post(`holiday`, body);
      }
      this.$store.dispatch("auth/getGlobalState");
      logInfo("Éxito !");
      this.showEdit = false;
      this.getDataFromApi();
    },
    async onClickDelete(item) {
      if (window.confirm(this.$t("confirmdelete"))) {
        this.isDeleting = true;
        try {
          await ApiService.delete(`holiday/${item.id}`);
          this.isDeleting = false;
          this.getDataFromApi();
          this.showEdit = false;
          logInfo("Éxito !");
        } catch (error) {
          console.log(error);
          this.isDeleting = false;
        }
      }
    },

    async downloadExcel() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "holiday/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const response = await ApiService.get(fetch_url, {
        responseType: "blob"
      });
      download(response, "data.xlsx");
      this.close();
    },

    async onChangePaidType() {
      if (
        this.selected.paidType &&
        this.paid_day_limits[this.selected.paidType]
      ) {
        this.selected.limit_paid_days = this.paid_day_limits[
          this.selected.paidType
        ];
        this.selected.isSelectedNaturalMode = this.isNaturals_paid_day_limits[
          this.selected.paidType
        ];
        await this.onChangeDates();
      }
    },

    init() {
      this.getDataFromApi();
    }
  },
  mounted() {
    this.$store.dispatch("auth/getGlobalState");
    this.init();
  }
};
</script>

<style>
.title_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title_wrapper .btn_status {
  cursor: initial;
}
.color-red {
  color: red !important;
}
</style>
